<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    class="svgCompartir"
  >
    <path
      d="M16 9C17.6569 9 19 7.65685 19 6C19 4.34315 17.6569 3 16 3C14.3431 3 13 4.34315 13 6C13 7.65685 14.3431 9 16 9Z"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M4 16C5.65685 16 7 14.6569 7 13C7 11.3431 5.65685 10 4 10C2.34315 10 1 11.3431 1 13C1 14.6569 2.34315 16 4 16Z"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M16 23C17.6569 23 19 21.6569 19 20C19 18.3431 17.6569 17 16 17C14.3431 17 13 18.3431 13 20C13 21.6569 14.3431 23 16 23Z"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M6.59009 14.51L13.4201 18.49"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M13.4101 7.50999L6.59009 11.49"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  name: "IconCompartir",
  props: {
    pet: Object,
  },
};
</script>
