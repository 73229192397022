<template>
  <section id="petHeader">
    <div class="container">
      <div class="row">
        <div class="col-12 col-md-12 col-lg-6 col-xl-6">
          <div class="card-img">
            <div
              :id="`animal_${this.pet.id}`"
              :class="`card-img-inner animal_${Math.floor(Math.random() * 60)}`"
            >
              <img :src="pet.image_url" :alt="pet.name" />
            </div>
          </div>
        </div>
        <div class="col-12 col-md-12 col-lg-6 col-xl-6">
          <h1>
            {{ pet.name }} <span>{{ getEdad }} años, Barcelona</span>
          </h1>
          <div id="petDetails">
            <div>
              <pet-row title="Especie" :text="pet.species" />
              <pet-row title="Sexo" :text="pet.sex" />
              <pet-row title="Medida" :text="pet.size" />
              <pet-row
                title="Fecha de nacimiento"
                :text="new Date(pet.birth_date).toLocaleDateString('es-ES')"
              />
              <pet-row title="Estado" :text="pet.status" />
            </div>
            <div class="btnRow">
              <a
                class="btn btn-primary"
                @click.prevent="this.$router.push('/adoptar')"
              >
                Lo quiero adoptar
              </a>
              <a
                class="btn btn-light"
                @click.prevent="this.$router.push('/compartir')"
              >
                <div class="btnFlex">
                  <icon-compartir />
                  <span>Compartir</span>
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import PetRow from "@/components/PetDetail/PetRow.vue";
import IconCompartir from "@/assets/icons/icon_compartir.vue";

export default {
  name: "PetHeader",
  components: {
    PetRow,
    IconCompartir,
  },
  props: {
    pet: Object,
  },
  computed: {
    getEdad() {
      const hoy = new Date();
      const cumpleanos = new Date(this.pet.birth_date);
      const month = hoy.getMonth() - cumpleanos.getMonth();
      let edad = hoy.getFullYear() - cumpleanos.getFullYear();

      if (month < 0 || (month === 0 && hoy.getDate() < cumpleanos.getDate())) {
        edad--;
      }

      return edad;
    },
  },
};
</script>

<style scoped lang="sass">
#petHeader
  padding: 7rem 0 0 0
  .container
    .row
      display: flex
      justify-content: space-between
    .card-img
      padding: 1.2rem
      box-shadow: 0px 0px 4px 1px rgba(0, 0, 0, 0.1)
      width: 100%
      height: 380px
      overflow: hidden
      .card-img-inner
        width: 100%
        height: 100%
        position: relative
        overflow: hidden
        img
          position: absolute
          bottom: 0
          margin-left: 50%
          transform: translateX(-50%) translateY(15%) scale(0.7)

    h1
      font-weight: 800
      font-size: 2.2rem
      font-family: $font-family-title
      margin: 2rem 0
      span
        color: $primary

    #petDetails
      width: 100%
      .btnRow
        margin-top: 2rem
        display: grid
        grid-template-columns: repeat(2, 1fr)
        gap: 1rem
        .btn
          font-size: .95rem
          font-weight: 400
          padding: .6rem
          .btnFlex
            display: flex
            align-items: flex-end
            justify-content: center
            img
              .svgCompartir
                stroke: $primary
          &:hover .svgCompartir
            stroke: white

/* Large devices (desktops, 992px and up)*/
@media (min-width: $md)
  #petHeader
    padding: 7rem 2rem 0 2rem
    .container
      padding: 2rem 2rem 0 2rem
      .card-img
        width: 525px
        height: 525px
        max-width: 100%
        max-height: 100%
        .card-img-inner
          width: 100%
          height: 100%
          position: relative
          overflow: hidden
          img
            transform: translateX(-50%)
      #petDetails
        width: 365px
</style>

//Global
<style lang="sass">
#petHeader
  .container
    #petDetails
      .btnRow
        .btn
          .btnFlex
            .svgCompartir
              margin-right: .5rem
              stroke: $primary
          &:hover .svgCompartir
            stroke: white
</style>
