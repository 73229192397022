<template>
  <header class="header">
    <nav
      id="mainNav"
      class="navbar fixed-top navbar-expand-lg navbar-light bg-transparent"
    >
      <div class="container">
        <a class="navbar-brand" @click.prevent="this.$router.push('/')">
          <img
            id="logo"
            :src="img"
            alt="Logo Petinder"
            width="177"
            height="55"
          />
        </a>
        <button
          id="btnToogler"
          class="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbar"
          aria-controls="navbar"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbar">
          <ul class="navbar-nav me-auto">
            <li class="nav-item">
              <a
                class="nav-link"
                @click.prevent="this.$router.push('/servicios')"
                >Servicios</a
              >
            </li>
            <li class="nav-item">
              <a
                class="nav-link"
                @click.prevent="this.$router.push('/nosotros')"
                >Sobre nosotros</a
              >
            </li>
            <li class="nav-item">
              <a
                class="nav-link"
                @click.prevent="this.$router.push('/contactar')"
                >Contactar</a
              >
            </li>
            <li class="nav-item" v-if="isLoggedIn">
              <a class="nav-link" @click.prevent="this.$router.push('/new')"
                >Nueva mascota</a
              >
            </li>
          </ul>
          <form v-if="!isLoggedIn" class="d-flex">
            <a
              class="btn btn-light w-100"
              @click.prevent="this.$router.push('/login')"
              href="/login"
            >
              Iniciar sesión
            </a>
          </form>
          <div v-else>
            <div class="loggedIn">
              <span
                :class="{
                  'text-white': currentUserWhite,
                  'text-dark': !currentUserWhite,
                }"
                >{{ currentUser }}</span
              >
              <div class="avatar-circle"><span class="avatar">🦁</span></div>
              <icon-logout @click="this.logout()" class="iconLogoutXl" />
            </div>
            <div class="logoutRow">
              <icon-logout @click="this.logout()" class="iconLogoutSm" />
            </div>
          </div>
        </div>
      </div>
    </nav>
  </header>
</template>

<script>
import { getAuth, signOut } from "firebase/auth";
import IconLogout from "@/assets/icons/icon_logout.vue";

export default {
  name: "AppHeader",
  components: {
    IconLogout,
  },
  data() {
    return {
      imgColor: "petinder_white",
      isLoggedIn: Boolean,
      currentUser: String,
    };
  },
  computed: {
    img: function () {
      return require(`@/assets/images/logos/${this.imgColor}.svg`);
    },
    currentUserWhite: function () {
      if (window.screen.width <= 991) {
        return false;
      }
      return this.imgColor === "petinder_white" ? true : false;
    },
  },
  created() {
    this.isLoggedIn = false;
    this.currentUser = undefined;
  },
  mounted() {
    window.addEventListener("scroll", this.onScroll);
    window.addEventListener("click", this.onClick);
  },
  beforeUnmount() {
    window.removeEventListener("scroll", this.onScroll);
    window.removeEventListener("click", this.onClick);
  },
  watch: {
    // eslint-disable-next-line no-unused-vars
    $route(to, from) {
      // CHECK LOGGED IN
      const user = JSON.parse(localStorage.getItem("user"));
      if (user?.email) {
        this.isLoggedIn = true;
        this.currentUser = user?.email;
      }

      // CHECK LOGO IMG
      if (to.name !== "Home") {
        this.imgColor = "petinder";
        return;
      }
      this.imgColor = "petinder_white";
      this.onScroll(null);
      return;
    },
  },
  methods: {
    onScroll(event) {
      const nav = document.getElementById("mainNav");

      const navHeight = nav.offsetHeight;
      const scrollTop =
        document.body.scrollTop ||
        event?.target.documentElement.scrollTop ||
        document.documentElement.scrollTop;

      if (scrollTop > navHeight) {
        nav.classList.remove("bg-transparent");
        nav.classList.add("bg-white");
        this.imgColor = "petinder";
        return;
      }

      nav.classList.remove("bg-white");
      nav.classList.add("bg-transparent");
      if (this.$router.currentRoute.value.name !== "Home") {
        this.imgColor = "petinder";
        return;
      }
      this.imgColor = "petinder_white";
      return;
    },
    onClick(event) {
      const clickover = event.target;
      const opened = document
        .getElementById("navbar")
        .classList.contains("show");
      if (
        opened === true &&
        !clickover.classList.contains("navbar-toggler-icon")
      ) {
        document.getElementById("btnToogler").click();
      }
      return;
    },
    logout() {
      const auth = getAuth();
      signOut(auth)
        .then(() => {
          this.isLoggedIn = false;
          this.currentUser = undefined;
          localStorage.removeItem("user");
          this.$router.push("/");
        })
        // eslint-disable-next-line no-unused-vars
        .catch((error) => {
          //const errorCode = error.code;
          //const errorMessage = error.message;
          //console.log(errorCode);
          //console.log(errorMessage);
          this.isLoggedIn = false;
          this.currentUser = undefined;
          localStorage.removeItem("user");
          this.$router.push("/");
        });
    },
  },
};
</script>

<style scoped lang="sass">
header
  #mainNav
    &.navbar-light
      padding-top: 1rem
      padding-bottom: 1rem
      transition: all 0.3s ease-in-out
      .navbar-brand
        transition: all 0.3s ease-out
        cursor: pointer
        &:hover
          transform: scale(1.1)

      .navbar-collapse
        flex-basis: 100%
        flex-grow: 1
        align-items: center
        padding: 1rem 1rem
        background-color: rgba(255, 255, 255, 0.9)
        backdrop-filter: blur(2px)
        .navbar-nav
          .nav-link
            color: rgba(0, 0, 0, 1)
            font-weight: 500
            transition: all 0.2s ease-out
            cursor: pointer
            &:focus,
            &:hover
              color: rgba(0, 0, 0, 0.55)
        a.btn
          margin-top: 1rem
          margin-bottom: 0.5rem

      .loggedIn
        padding: 0.5rem 0
        display: flex !important
        align-items: center
        span
          font-weight: 500
        .avatar-circle
          cursor: pointer
          margin: 0 1rem
          background: $grey-light
          padding: 0rem
          border-radius: 50px
          transition: all 0.3s ease-in-out
          &:hover
            -webkit-box-shadow: 0px 2px 7px 0px rgba(85, 85, 85, 0.95)
            box-shadow: 0px 2px 7px 0px rgba(85, 85, 85, 0.95)
        .iconLogoutXl
          display: none
          cursor: pointer

      .logoutRow
        padding: 0.5rem 0

/* Large devices (desktops, 992px and up)*/
@media (min-width: $md)
  header
    #mainNav
      &.navbar-light
        .navbar-collapse
          padding: 0
          background-color: transparent
          backdrop-filter: blur(0px)
          .navbar-nav
            .nav-link
              padding-right: 0.75rem
              padding-left: 0.75rem
          a.btn
            margin: 0

        .loggedIn
          .avatar-circle
            padding: 1rem
          .iconLogoutXl
            display: inherit
        .logoutRow
          display: none
</style>
