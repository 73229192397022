<template>
  <section id="petDescription">
    <div class="container">
      <div id="bioBox">
        <h2>Mi historia</h2>
        <p>{{ pet.bio }}</p>
      </div>
      <div id="extraBox">
        <div>
          <h3 class="mb-4">Mi personalidad</h3>
          <pet-personality
            v-for="(pers, index) in this.pet.personality"
            :key="index"
            :text="pers"
          />
        </div>
        <div>
          <h3>Me entregan</h3>
          <div id="petDetails">
            <pet-row
              title="Vacunado"
              titleClass="status"
              :text="pet.handle_status.vaccinated.toString()"
            />
            <pet-row
              title="Desparasitado"
              titleClass="status"
              :text="pet.handle_status.dewormed.toString()"
            />
            <pet-row
              title="Esterilizado"
              titleClass="status"
              :text="pet.handle_status.sterilized.toString()"
            />
            <pet-row
              title="Microchip"
              titleClass="status"
              :text="pet.handle_status.microchip.toString()"
            />
          </div>
        </div>
        <div v-if="this.pet.info_notes.length > 0">
          <h3>Tienes que saber</h3>
          <pet-row
            v-for="(info, index) in this.pet.info_notes"
            :key="index"
            :text="info"
            :title="undefined"
          />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import PetPersonality from "@/components/PetDetail/PetPersonality.vue";
import PetRow from "@/components/PetDetail/PetRow.vue";

export default {
  name: "PetDescription",
  components: {
    PetPersonality,
    PetRow,
  },
  props: {
    pet: Object,
  },
};
</script>

<style scoped lang="sass">
#petDescription
  margin: 2.5rem 0
  .container
    #bioBox
      padding: 1.5rem
      background-color: #F9FDEE
      border: 1px solid #7FF38B
      h2
        font-weight: 600
        font-size: 1.1rem
      p
        font-size: 1rem
        margin: 0
    #extraBox
      margin-top: 2rem
      display: grid
      grid-template-columns: repeat(1, 1fr)
      gap: 2rem
      h3
        font-weight: 700
        font-size: 1.1rem

/* Large devices (desktops, 992px and up)*/
@media (min-width: $md)
  #petDescription
    margin: 0
    padding: 1.5rem 0
    .container
      padding: 2rem 2rem 0 2rem
      #extraBox
        margin-top: 2.5rem
        grid-template-columns: repeat(3, 1fr)
        gap: 4rem
</style>
