<template>
  <loader v-if="loading" mensaje="Cargando..." class="mb-5 mt-loader" />
  <div v-else class="petdetail">
    <pet-header :pet="pet" />
    <pet-description :pet="pet" />
    <pet-footer :pet="pet" />
  </div>
</template>

<script>
import { getPet } from "@/db";
import PetHeader from "@/components/PetDetail/PetHeader.vue";
import PetDescription from "@/components/PetDetail/PetDescription.vue";
import PetFooter from "@/components/PetDetail/PetFooter.vue";
import Loader from "@/components/Common/Loader.vue";

export default {
  name: "PetDetail",
  components: {
    PetHeader,
    PetDescription,
    PetFooter,
    Loader,
  },
  data() {
    return {
      pet: {
        id: "",
        name: "",
        image_url: "",
        location: "",
        species: "",
        sex: "",
        size: "",
        birth_date: "",
        handle_status: {
          vaccinated: false,
          dewormed: false,
          sterilized: false,
          microchip: false,
        },
        bio: "",
        status: "",
        personality: [],
        info_notes: [],
      },
      loading: true,
    };
  },
  async created() {
    this.pet = await getPet(this.$router.currentRoute.value.params.id);
    this.loading = false;
  },
  mounted() {
    window.scrollTo(0, 0);
  },
};
</script>

<!-- Añadimos "scoped" para limitar el CSS a este componente -->
<style scoped lang="sass">
.mt-loader
  margin-top: 13rem
</style>
