import { initializeApp } from "firebase/app";

const firebaseConfig = {
  apiKey: "AIzaSyD9zEoZCVTEO2971EF48j__bVzha4JR9ow",
  authDomain: "petinder-6d897.firebaseapp.com",
  projectId: "petinder-6d897",
  storageBucket: "petinder-6d897.appspot.com",
  messagingSenderId: "170621589891",
  appId: "1:170621589891:web:50a18c3688bee445c4beb6",
};

const app = initializeApp(firebaseConfig);

export { app };
