<template>
  <p class="petPersonality">{{ text }}</p>
</template>

<script>
export default {
  name: "PetPersonality",
  props: {
    text: String,
  },
};
</script>

<style scoped lang="sass">
.petPersonality
  background-color: $primary
  color: white
  padding: 0.15rem 0.6rem
  font-size: .85rem
  border-radius: 50px
  margin: 0 .7rem 0.7rem 0
  display: inline-block
</style>
