import "@/firebase";

import {
  getFirestore,
  collection,
  doc,
  setDoc,
  getDoc,
  getDocs,
  query,
  where,
} from "firebase/firestore";

const db = getFirestore();
const petCollection = collection(db, "pets");

export default db;

export async function addPet(pet) {
  const petRef = doc(petCollection);
  pet.id = petRef.id;
  //console.log(pet);
  try {
    await setDoc(doc(petCollection, petRef.id), pet);
    //console.log("Document written with ID: ", pet.id);
    return true;
  } catch (e) {
    //console.error("Error adding document: ", e);
    return false;
  }
}

export async function getPet(id) {
  //console.log("getPet");
  //console.log(id);
  const docRef = doc(petCollection, id);
  const docSnap = await getDoc(docRef);

  if (docSnap.exists()) {
    //console.log("Document data:", docSnap.data());
    return docSnap.data();
  } else {
    // doc.data() will be undefined in this case
    //console.log("No such document!");
  }
}

export async function getAllPets() {
  const querySnapshot = await getDocs(petCollection);
  const pets = [];
  querySnapshot.forEach((doc) => {
    const pet = doc.data();
    pets.push(pet);
  });
  return pets;
}

export async function getFilteredPets(queryText) {
  const nameQuery = query(
    petCollection,
    where("name", ">=", queryText.charAt(0).toUpperCase() + queryText.slice(1)),
    where(
      "name",
      "<=",
      queryText.charAt(0).toUpperCase() + queryText.slice(1) + "\uf8ff"
    )
  );
  const querySnapshot = await getDocs(nameQuery);
  const pets = [];
  querySnapshot.forEach((doc) => {
    const pet = doc.data();
    pets.push(pet);
  });
  return pets;
}
