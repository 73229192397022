<template>
  <section id="actions">
    <div class="container">
      <div class="row">
        <div class="col-6 col-md-4 col-lg-2">
          <a href="#">
            <div class="icon mx-auto">
              <img
                src="@/assets/icons/icon_apadrina.svg"
                class="d-block mx-auto"
                alt="Icono Apadrina"
              />
            </div>
            <div class="content">
              <h4 class="mt-3 mb-1">Apadrina</h4>
              <p class="mb-0">Mientras llega la adopción</p>
            </div>
          </a>
        </div>
        <div class="col-6 col-md-4 col-lg-2">
          <a href="#">
            <div class="icon mx-auto">
              <img
                src="@/assets/icons/icon_adopta.svg"
                class="d-block mx-auto"
                alt="Icono Adopta"
              />
            </div>
            <div class="content">
              <h4 class="mt-3 mb-1">Adopta</h4>
              <p class="mb-0">El amigo que necesitas</p>
            </div>
          </a>
        </div>
        <div class="col-6 col-md-4 col-lg-2">
          <a href="#">
            <div class="icon mx-auto">
              <img
                src="@/assets/icons/icon_socio.svg"
                class="d-block mx-auto"
                alt="Icono Socio"
              />
            </div>
            <div class="content">
              <h4 class="mt-3 mb-1">Hazte socio</h4>
              <p class="mb-0">Solo 10€/mes</p>
            </div>
          </a>
        </div>
        <div class="col-6 col-md-4 col-lg-2">
          <a href="#">
            <div class="icon mx-auto">
              <img
                src="@/assets/icons/icon_donaciones.svg"
                class="d-block mx-auto"
                alt="Icon Donaciones"
              />
            </div>
            <div class="content">
              <h4 class="mt-3 mb-1">Donaciones</h4>
              <p class="mb-0">Con poco haces mucho</p>
            </div>
          </a>
        </div>
        <div class="col-6 col-md-4 col-lg-2">
          <a href="#">
            <div class="icon mx-auto">
              <img
                src="@/assets/icons/icon_empresas.svg"
                class="d-block mx-auto"
                alt="Icon Empresas"
              />
            </div>
            <div class="content">
              <h4 class="mt-3 mb-1">Empresas solidarias</h4>
              <p class="mb-0">Descubre como colaborar</p>
            </div>
          </a>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "Actions",
};
</script>

<style scoped lang="sass">
#actions
  background: linear-gradient(180deg, $accent-light 0%, $accent 100%)
  div.row
    justify-content: center
    padding: 3rem 0 0 0
    div.col-6
      margin-bottom: 2.5rem
      a
        text-decoration: none
        color: inherit
        transition: all 0.3s ease-in-out
        &:hover
          text-decoration: none
          color: #392bb7
          img
            transform: scale(1.1)
          .icon
            -webkit-box-shadow: 0px 2px 7px 0px rgba(85, 85, 85, 0.95)
            box-shadow: 0px 2px 7px 0px rgba(85, 85, 85, 0.95)

        .icon
          display: flex
          align-items: center
          background-color: #fff
          border-radius: 50%
          width: 73px
          height: 73px
          transition: all 0.3s ease-in-out
          img
            transition: all 0.3s ease-in-out

        .content
          text-align: center
          letter-spacing: -0.015em
          h4
            font-weight: 700
            font-size: 1.15rem
            margin-bottom: 0
            letter-spacing: -0.015em

/* Large devices (desktops, 992px and up)*/
@media (min-width: $md)
  #actions
    div.row
      justify-content: space-between
      padding: 3rem 0
      div.col-6
        margin-bottom: 0
</style>
