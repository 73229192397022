<template>
  <section id="petFooter">
    <div class="container-fluid">
      <div class="inner">
        <p>
          Si <span>{{ pet.name }}</span> ya ha sido adoptado/a o no hacéis
          match, tenemos otros animales que te necesitan. Encuéntralos
        </p>
        <search-bar />
      </div>
    </div>
  </section>
</template>

<script>
import SearchBar from "@/components/Common/SearchBar.vue";

export default {
  name: "PetFooter",
  components: {
    SearchBar,
  },
  props: {
    pet: Object,
  },
};
</script>

<style scoped lang="sass">
#petFooter
  padding: 0
  .container-fluid
    padding: 2rem 1rem
    background: linear-gradient(73.24deg, $accent-light 0%, $accent 100%)
    .inner
      padding: 0
      display: flex
      flex-direction: column
      max-width: 476px
      margin: 0 auto
      p
        text-align: center
        font-size: 1.1rem
        span
          font-weight: 800

/* Large devices (desktops, 992px and up)*/
@media (min-width: $md)
  #petFooter
    padding: 2rem 0 0 0
    .container-fluid
      padding: 2.5rem
</style>
