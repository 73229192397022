<template>
  <section class="page_404 pt-32 sm:pt-40 md:pt-48 xl:pt-50">
    <div class="container mx-auto">
      <div class="row">
        <h1 class="text-center">404</h1>
        <div class="bg_404"></div>

        <div class="content_box_404 text-center">
          <div>
            <h3 class="h2">Parece que te has perdido</h3>
            <p>la página que buscas no está disponible!</p>
          </div>
          <a
            class="btn btn-primary link_404"
            @click.prevent="this.$router.push('/')"
          >
            Ir al inicio
          </a>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "NotFound",
  mounted() {
    window.scrollTo(0, 0);
  },
};
</script>

<!-- Añadimos "scoped" para limitar el CSS a este componente -->

<style scoped lang="sass">
.page_404
  padding: 10rem 0 0 0

  img
    width: 100%

  h1
    font-size: 80px

  .bg_404
    background-image: url('../assets/images/404.gif')
    height: 400px
    background-position: center
    background-repeat: no-repeat

    h3
      font-size: 80px

  .link_404
    margin: 20px 0

  .content_box_404
    margin-top: -50px

  .btn
    width: 100%

/* Large devices (desktops, 992px and up)*/
@media (min-width: $md)
  .page_404
    .btn
      width: 50%
</style>
