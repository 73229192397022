<template>
  <div class="d-flex flex-column align-items-center">
    <h6 class="mb-3">{{ mensaje }}</h6>
    <div
      class="spinner-grow text-primary"
      style="width: 3rem; height: 3rem"
      role="status"
    ></div>
  </div>
</template>

<script>
export default {
  name: "Loader",
  props: {
    mensaje: String,
  },
};
</script>
