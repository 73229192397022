<template>
  <main class="page-register">
    <section class="box-auth">
      <img class="logo" alt="logo" src="@/assets/images/logos/peticon.svg" />
      <h1 class="main-title">Registro</h1>
      <p class="auth-intro">
        Regístrate en Petinder y encuentra a tu nuevo mejor amigo.
      </p>

      <form>
        <div class="form-group">
          <label class="form-label" for="username">Email</label>
          <input
            placeholder="Correo electrónico"
            type="email"
            id="email"
            class="form-control"
            v-model="email"
          />
        </div>
        <div class="form-group">
          <label class="form-label" for="password">Contraseña</label>
          <input
            placeholder="Contraseña"
            type="password"
            id="password"
            class="form-control"
            v-model="password"
          />
        </div>
        <div v-if="error !== null" class="form-error">
          <span>{{ error }}</span>
        </div>
        <a v-on:click="register" class="btn btn-primary">Registrar</a>
      </form>

      <p class="auth-bottom">
        ¿Ya tienes cuenta?
        <span class="alink" @click="this.$router.push('/login')">
          Inicia sesión
        </span>
      </p>
    </section>
  </main>
</template>

<script>
import { getAuth, createUserWithEmailAndPassword } from "firebase/auth"; // completar import

export default {
  name: "register",
  data: function () {
    return {
      email: "",
      password: "",
      error: null,
    };
  },
  mounted() {
    window.scrollTo(0, 0);
    const user = JSON.parse(localStorage.getItem("user"));
    if (user?.email) {
      this.$router.push("/");
    }
  },
  methods: {
    register: function (e) {
      e.preventDefault();
      if (this.email === "") {
        this.error = "Introduce un email válido";
      } else if (this.password == "") {
        this.error = "Introduce una contraseña";
      } else {
        const auth = getAuth();
        createUserWithEmailAndPassword(auth, this.email, this.password)
          .then((userCredential) => {
            //console.log(userCredential);
            //console.log(
            //  `Nuevo usuario creado con el correo: ${userCredential.user.email}`
            //);
            localStorage.setItem("user", JSON.stringify(userCredential?.user));
            this.$router.push("/");
          })
          .catch((error) => {
            const errorCode = error.code;
            const errorMessage = error.message;
            //console.log(errorCode);
            //console.log(errorMessage);
            switch (errorCode) {
              case "auth/invalid-email":
                this.error = "Introduce un email válido";
                break;
              case "auth/weak-password":
                this.error = "La contraseña debe tener al menos 6 carácteres";
                break;
              case "auth/email-already-in-use":
                this.error = "Usuario registrado previamente. Inicia sesión";
                break;
              default:
                this.error = errorMessage.replace("Firebase: ", "");
                break;
            }
          });
      }
    },
  },
};
</script>
