import { createRouter, createWebHistory } from "vue-router";
import Home from "@/views/Home.vue";
import PetDetail from "@/views/PetDetail.vue";
import Login from "@/views/Login.vue";
import Register from "@/views/Register.vue";
import NotFound from "@/views/NotFound.vue";
import NewPet from "@/views/NewPet.vue";
import SearchResults from "@/views/SearchResults.vue";

const routes = [
  { path: "/", component: Home, name: "Home" },
  { path: "/pet/:id", component: PetDetail, name: "PetDetail" },
  { path: "/login", component: Login, name: "Login" },
  { path: "/register", component: Register, name: "Register" },
  { path: "/:pathMatch(.*)*", component: NotFound, name: "NotFound" },
  { path: "/new", component: NewPet, name: "NewPet" },
  { path: "/search/:text", component: SearchResults, name: "SearchResults" },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
