<template>
  <div class="home">
    <hero />
    <section id="main">
      <div class="container">
        <h2>Últimas incorporaciones</h2>
        <loader v-if="loading" mensaje="Cargando..." class="mb-5" />
        <pet-list v-else :pets="pets" />
      </div>
    </section>
    <actions />
  </div>
</template>

<script>
import { getAllPets } from "@/db";
//import pets from "../api/pets.json";
import Hero from "@/components/Home/Hero.vue";
import PetList from "@/components/Home/PetList.vue";
import Actions from "@/components/Home/Actions.vue";
import Loader from "@/components/Common/Loader.vue";

export default {
  name: "Home",
  components: {
    Hero,
    PetList,
    Actions,
    Loader,
  },
  data() {
    return {
      loading: true,
      pets: [],
    };
  },
  async created() {
    this.pets = await getAllPets();
    this.loading = false;
  },
  mounted() {
    window.scrollTo(0, 0);
  },
};
</script>

<!-- Añadimos "scoped" para limitar el CSS a este componente -->
<style scoped lang="sass">
#main
  h2
    font-weight: 800
    font-size: 2.2rem
    font-family: $font-family-title
    margin: 2.5rem 0

/* Large devices (desktops, 992px and up)*/
@media (min-width: $md)
  #main
    h2
      margin: 3.5rem 0
</style>
