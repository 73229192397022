<template>
  <svg
    width="24"
    height="21"
    viewBox="0 0 24 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M18.4242 4.72002L24 10.04L18.4242 15.32V11.78H10.0808V8.26001H18.4242V4.72002ZM15.0707 14.96L17.2121 17.1C15.1111 19.0467 12.835 20.02 10.3838 20.02C7.46128 20.02 5 19.0633 3 17.15C1 15.2367 0 12.8467 0 9.98001C0 8.18001 0.457912 6.51335 1.37374 4.98002C2.28956 3.44668 3.52525 2.23668 5.08081 1.35002C6.63636 0.463353 8.32323 0.0200195 10.1414 0.0200195C12.6195 0.0200195 14.9697 1.00669 17.1919 2.98002L15.0707 5.10002C13.5354 3.71335 11.899 3.02002 10.1616 3.02002C8.15488 3.02002 6.46128 3.71335 5.08081 5.10002C3.70034 6.48668 3.0101 8.18001 3.0101 10.18C3.0101 12.0467 3.72054 13.6533 5.14141 15C6.56229 16.3467 8.22896 17.02 10.1414 17.02C11.9057 17.02 13.5488 16.3333 15.0707 14.96Z"
      fill="#4F3BFF"
    />
  </svg>
</template>

<script>
export default {
  name: "IconLogout",
  props: {
    pet: Object,
  },
};
</script>
