<template>
  <main class="page-login">
    <section class="box-auth">
      <img class="logo" alt="logo" src="@/assets/images/logos/peticon.svg" />
      <h1 class="main-title">Iniciar sesión</h1>
      <p class="auth-intro">
        Inicia sesión en Petinder y encuentra a tu nuevo mejor amigo.
      </p>

      <form>
        <div class="form-group">
          <label class="form-label" for="username">Email</label>
          <input
            placeholder="Correo electrónico"
            type="email"
            id="email"
            class="form-control"
            v-model="email"
          />
        </div>
        <div class="form-group">
          <label class="form-label" for="password">Contraseña</label>
          <input
            placeholder="Contraseña"
            type="password"
            id="password"
            class="form-control"
            v-model="password"
          />
        </div>
        <div v-if="error !== null" class="form-error">
          <span>{{ error }}</span>
        </div>
        <a v-on:click="login" class="btn btn-primary"> Iniciar sesión </a>
      </form>

      <p class="auth-bottom">
        ¿No tienes cuenta?
        <span class="alink" @click="this.$router.push('/register')">
          Regístrate
        </span>
      </p>
    </section>
  </main>
</template>

<script>
import { getAuth, signInWithEmailAndPassword } from "firebase/auth"; // completar import

export default {
  name: "login",
  data: function () {
    return {
      email: "",
      password: "",
      error: null,
    };
  },
  mounted() {
    window.scrollTo(0, 0);
    const user = JSON.parse(localStorage.getItem("user"));
    if (user?.email) {
      this.$router.push("/");
    }
  },
  methods: {
    login: function (e) {
      e.preventDefault();
      if (this.email === "") {
        this.error = "Introduce un email válido";
      } else if (this.password == "") {
        this.error = "Introduce una contraseña";
      } else {
        this.error = null;
        const auth = getAuth();
        signInWithEmailAndPassword(auth, this.email, this.password)
          .then((userCredential) => {
            //console.log(userCredential);
            //console.log(
            //  `Sesión iniciada correctamente con el correo: ${userCredential.user.email}`
            //);
            localStorage.setItem("user", JSON.stringify(userCredential?.user));
            this.$router.push("/");
          })
          .catch((error) => {
            const errorCode = error.code;
            const errorMessage = error.message;
            //console.log(errorCode);
            //console.log(errorMessage);
            switch (errorCode) {
              case "auth/wrong-password":
                this.error = "Datos de acceso incorrectos";
                break;
              default:
                this.error = errorMessage;
                break;
            }
          });

        e.preventDefault();
      }
    },
  },
};
</script>
