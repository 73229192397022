<template>
  <div class="tablaRow">
    <p class="title" :class="titleClass" v-if="title !== undefined">
      {{ title }}
    </p>
    <p class="text">
      <span v-if="text === 'true'" class="badge true">Si</span>
      <span v-else-if="text === 'false'" class="badge false">No</span>
      <span v-else> {{ text }} </span>
    </p>
  </div>
</template>

<script>
export default {
  name: "PetRow",
  props: {
    title: String,
    titleClass: String,
    text: String,
  },
};
</script>

<style scoped lang="sass">
.tablaRow
  display: flex
  justify-content: space-between
  border-bottom: 1px solid $primary-light
  &:last-child
    border-bottom: 0
  p
    padding: .9rem 0
    margin: 0
    &.title
      font-weight: 600
    &.title.status
      font-weight: 400
    &.text
      .badge
        padding: .25rem .55rem
        border-radius: 50px
        font-size: .8rem
        &.true
          background-color: $accent
          color: white
        &.false
          background-color: #FF9B9B
          color: white

/* Large devices (desktops, 992px and up)*/
@media (min-width: $md)
  .tablaRow
    &:last-child
    p
      &.title.status
        font-weight: 600
</style>
