<template>
  <form>
    <label class="visually-hidden" for="petFinder">Pet Finder</label>
    <div class="input-group">
      <div class="input-group-text pointer" @click="search">
        <img src="@/assets/icons/icon_search.svg" alt="Icono Lupa Buscador" />
      </div>
      <input
        id="petFinder"
        type="text"
        class="form-control"
        placeholder="Busca a tu nuevo mejor amigo"
        v-model="input"
        @keypress.enter.prevent.self="search"
      />
    </div>
  </form>
</template>

<script>
export default {
  name: "SearchBar",
  props: {
    pet: Object,
  },
  data() {
    return {
      loading: false,
      input: "",
      error: null,
      success: null,
    };
  },
  methods: {
    search: function () {
      if (this.input) {
        this.$router.push(`/search/${this.input}`);
      }
    },
  },
};
</script>

<style scoped lang="sass">
.pointer
    cursor: pointer

.input-group
    width: 100%
    z-index: 10
    margin-bottom: 1rem
    input
        padding: 0.75rem 1rem
        border-left: 0px

/* Large devices (desktops, 992px and up)*/
@media (min-width: $md)
    .input-group
        input
            padding: 1rem 2rem
</style>
