<template>
  <footer class="footer">
    <div class="container d-flex">
      <span class="align-middle text-white">2021 Petinder</span>
      <ul class="ms-auto">
        <li class="nav-item">
          <a class="nav-link" @click.prevent="this.$router.push('/terminos')"
            >Términos y condiciones de uso</a
          >
        </li>
        <li class="nav-item">
          <a class="nav-link" @click.prevent="this.$router.push('/privacidad')"
            >Política de privacidad</a
          >
        </li>
        <li class="nav-item">
          <a class="nav-link" @click.prevent="this.$router.push('/cookies')"
            >Cookies</a
          >
        </li>
        <li class="nav-item">
          <a class="nav-link" @click.prevent="this.$router.push('/faq')">FAQ</a>
        </li>
      </ul>
    </div>
  </footer>
</template>

<script>
export default {
  name: "AppFooter",
};
</script>

<style scoped lang="sass">
footer
  background: $primary
  padding: 1.5rem 0
  font-weight: 500
  span
    padding: 0.5rem 1rem
  ul
    display: flex
    flex-direction: column
    margin-bottom: 0
    list-style: none
    .nav-link
      color: #fff
      cursor: pointer
      &:hover
        color: #b1ef83
      &:focus
        color: #b1ef83

/* Large devices (desktops, 992px and up)*/
@media (min-width: $md)
  footer
    ul
      flex-direction: row
</style>
