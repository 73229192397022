<template>
  <div class="cards">
    <div class="row">
      <pet-card v-for="(pet, index) in this.pets" :key="index" :pet="pet" />
    </div>
  </div>
</template>

<script>
import PetCard from "@/components/Home/PetCard.vue";

export default {
  name: "PetList",
  components: {
    PetCard,
  },
  props: {
    pets: Array,
  },
};
</script>
