<template>
  <div class="searchResults">
    <section id="main">
      <div class="container">
        <div class="d-xl-flex align-items-center">
          <h1
            v-if="busqueda || (!isFiltered() && !busquedaFiltrada)"
            class="d-block d-xl-inline-block me-3"
          >
            Resultados de: <span>{{ busqueda }}</span>
          </h1>
          <h1
            v-else-if="busqueda === ''"
            class="d-block d-xl-inline-block me-2"
          >
            Todos los resultados <span>{{ busqueda }}</span>
          </h1>
          <div v-if="isFiltered()">
            <h1
              v-if="busqueda"
              class="d-block d-xl-inline-block h1continuation"
            >
              Filtrados por:
            </h1>
            <h1 v-else class="d-block d-xl-inline-block h1continuation">
              filtrados por:
            </h1>
          </div>
          <span
            class="badge bg-primary"
            v-if="isFiltered() && filter.location"
            >{{ filter.location }}</span
          >
          <span
            class="badge bg-primary"
            v-if="isFiltered() && filter.species"
            >{{ filter.species }}</span
          >
          <span class="badge bg-primary" v-if="isFiltered() && filter.sex">{{
            filter.sex
          }}</span>
          <span class="badge bg-primary" v-if="isFiltered() && filter.size">{{
            filter.size
          }}</span>
          <span class="badge bg-primary" v-if="isFiltered() && filter.status">{{
            filter.status
          }}</span>
          <span
            class="badge bg-primary"
            v-if="isFiltered() && filter.handle_status.vaccinated"
            >Vacunado</span
          >
          <span
            class="badge bg-primary"
            v-if="isFiltered() && filter.handle_status.dewormed"
            >Desparasitado</span
          >
          <span
            class="badge bg-primary"
            v-if="isFiltered() && filter.handle_status.sterilized"
            >Esterilizado</span
          >
          <span
            class="badge bg-primary"
            v-if="isFiltered() && filter.handle_status.microchip"
            >Microchip</span
          >
        </div>
        <loader v-if="loading" mensaje="Buscando..." />
        <h5
          v-else-if="!loading && pets.length === 0"
          class="text-left text-xl-center my-5"
        >
          <p>
            No se ha encontrado ningún gatete ni perrete que coincida con la
            búsqueda...
          </p>
          <p>
            Utiliza la búsqueda avanzada para filtrar mejor o prueba a hacer una
            búsqueda sin nombre.
          </p>
        </h5>
        <pet-list v-else :pets="pets" class="mt-4" />
        <button
          v-if="!loading"
          class="btn btn-primary mb-4"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#advSearch"
          aria-expanded="false"
          aria-controls="advSearch"
        >
          Búsqueda avanzada
        </button>
        <div class="collapse" id="advSearch">
          <div class="card card-body mt-3 mb-5">
            <form class="">
              <div class="row">
                <div class="col-12 col-xl-6">
                  <!--NOMBRE-->
                  <div class="form-group">
                    <label class="btnLabels first mt-2" for="busqueda"
                      >Nombre</label
                    >

                    <input
                      :disabled="loading"
                      type="text"
                      id="busqueda"
                      class="form-control"
                      v-model="busqueda"
                    />
                  </div>

                  <!--ESPECIEs-->
                  <div class="form-group">
                    <label class="btnLabels" for="species">Especie</label>

                    <div class="d-flex justify-content-around">
                      <input
                        :disabled="loading"
                        type="radio"
                        class="btn-check"
                        v-model="filter.species"
                        value="Gato"
                        id="gato"
                        name="gato"
                        v-on:click="setValue('species', $event.target.value)"
                      />
                      <label
                        class="btn btn-outline-primary w-100 mx-1"
                        for="gato"
                        >Gato</label
                      >
                      <input
                        :disabled="loading"
                        type="radio"
                        class="btn-check"
                        v-model="filter.species"
                        value="Perro"
                        id="perro"
                        name="perro"
                        v-on:click="setValue('species', $event.target.value)"
                      />
                      <label
                        class="btn btn-outline-primary w-100 mx-1"
                        for="perro"
                        >Perro</label
                      >
                    </div>
                  </div>

                  <!--TAMAÑO-->
                  <div class="form-group">
                    <label class="btnLabels" for="size">Tamaño</label>

                    <div
                      class="d-flex justify-content-around flex-column flex-xl-row"
                    >
                      <input
                        :disabled="loading"
                        class="btn-check"
                        type="radio"
                        v-model="filter.size"
                        value="Pequeño"
                        id="pequeno"
                        name="pequeno"
                        v-on:click="setValue('size', $event.target.value)"
                      />
                      <label
                        class="btn btn-outline-primary w-100 mx-1 mb-3 mb-xl-0"
                        for="pequeno"
                        >Pequeño</label
                      >
                      <input
                        :disabled="loading"
                        class="btn-check"
                        type="radio"
                        v-model="filter.size"
                        value="Mediano"
                        id="mediano"
                        name="mediano"
                        v-on:click="setValue('size', $event.target.value)"
                      />
                      <label
                        class="btn btn-outline-primary w-100 mx-1 mb-3 mb-xl-0"
                        for="mediano"
                        >Mediano</label
                      >
                      <input
                        :disabled="loading"
                        class="btn-check"
                        type="radio"
                        v-model="filter.size"
                        value="Grande"
                        id="grande"
                        name="grande"
                        v-on:click="setValue('size', $event.target.value)"
                      />
                      <label
                        class="btn btn-outline-primary w-100 mx-1"
                        for="grande"
                        >Grande</label
                      >
                    </div>
                  </div>

                  <!--ESTADO DE LA ENTREGA-->
                  <div class="form-group">
                    <label class="btnLabels" for="handle_status"
                      >Estado en la entrega</label
                    >

                    <div
                      class="d-flex justify-content-around flex-column flex-xl-row"
                    >
                      <input
                        :disabled="loading"
                        class="btn-check"
                        type="checkbox"
                        id="vaccinated"
                        v-model="filter.handle_status.vaccinated"
                      />
                      <label
                        class="btn btn-outline-primary w-100 mx-1 mb-2 mb-xl-0"
                        for="vaccinated"
                      >
                        Vacunado
                      </label>
                      <input
                        :disabled="loading"
                        class="btn-check"
                        type="checkbox"
                        id="dewormed"
                        v-model="filter.handle_status.dewormed"
                      />
                      <label
                        class="btn btn-outline-primary w-100 mx-1 mb-2 mb-xl-0"
                        for="dewormed"
                      >
                        Desparasitado
                      </label>
                      <input
                        :disabled="loading"
                        class="btn-check"
                        type="checkbox"
                        id="sterilized"
                        v-model="filter.handle_status.sterilized"
                      />
                      <label
                        class="btn btn-outline-primary w-100 mx-1 mb-2 mb-xl-0"
                        for="sterilized"
                      >
                        Esterilizado
                      </label>
                      <input
                        :disabled="loading"
                        class="btn-check"
                        type="checkbox"
                        id="microchip"
                        v-model="filter.handle_status.microchip"
                      />
                      <label
                        class="btn btn-outline-primary w-100 mx-1"
                        for="microchip"
                      >
                        Microchip
                      </label>
                    </div>
                  </div>
                </div>
                <div class="col-12 col-xl-6">
                  <!--LOCALIZACION -->
                  <div class="form-group">
                    <label class="btnLabels first mt-2" for="location"
                      >Localización</label
                    >

                    <input
                      :disabled="loading"
                      type="text"
                      id="location"
                      class="form-control"
                      v-model="filter.location"
                    />
                  </div>

                  <!--SEXO-->
                  <div class="form-group">
                    <label class="btnLabels" for="sex">Sexo</label>

                    <div class="d-flex justify-content-around">
                      <input
                        :disabled="loading"
                        class="btn-check"
                        type="radio"
                        v-model="filter.sex"
                        value="Macho"
                        id="macho"
                        name="macho"
                        v-on:click="setValue('sex', $event.target.value)"
                      />
                      <label
                        class="btn btn-outline-primary w-100 mx-1"
                        for="macho"
                        >Macho</label
                      >
                      <input
                        :disabled="loading"
                        class="btn-check"
                        type="radio"
                        v-model="filter.sex"
                        value="Hembra"
                        id="hembra"
                        name="hembra"
                        v-on:click="setValue('sex', $event.target.value)"
                      />
                      <label
                        class="btn btn-outline-primary w-100 mx-1"
                        for="hembra"
                        >Hembra</label
                      >
                    </div>
                  </div>

                  <!--ESTADO-->
                  <div class="form-group">
                    <label class="btnLabels" for="status">Estado</label>

                    <div
                      class="d-flex justify-content-around flex-column flex-xl-row"
                    >
                      <input
                        :disabled="loading"
                        class="btn-check"
                        type="radio"
                        v-model="filter.status"
                        value="En adopción"
                        id="enAdopcion"
                        name="enAdopcion"
                        v-on:click="setValue('status', $event.target.value)"
                      />
                      <label
                        class="btn btn-outline-primary w-100 mx-1 mb-2 mb-xl-0"
                        for="enAdopcion"
                        >En adopción</label
                      >
                      <input
                        :disabled="loading"
                        class="btn-check"
                        type="radio"
                        v-model="filter.status"
                        value="Adoptado"
                        id="adoptado"
                        name="adoptado"
                        v-on:click="setValue('status', $event.target.value)"
                      />
                      <label
                        class="btn btn-outline-primary w-100 mx-1 mb-2 mb-xl-0"
                        for="adoptado"
                        >Adoptado</label
                      >
                      <input
                        :disabled="loading"
                        class="btn-check"
                        type="radio"
                        v-model="filter.status"
                        value="En acogida"
                        id="enAcogida"
                        name="enAcogida"
                        v-on:click="setValue('status', $event.target.value)"
                      />
                      <label
                        class="btn btn-outline-primary w-100 mx-1 mb-2 mb-xl-0"
                        for="enAcogida"
                        >En acogida</label
                      >
                      <input
                        :disabled="loading"
                        class="btn-check"
                        type="radio"
                        v-model="filter.status"
                        value="Acogido"
                        id="acogido"
                        name="acogido"
                        v-on:click="setValue('status', $event.target.value)"
                      />
                      <label
                        class="btn btn-outline-primary w-100 mx-1"
                        for="acogido"
                        >Acogido</label
                      >
                    </div>
                  </div>

                  <div class="form-group">
                    <label class="btnLabels" for="status"></label>
                    <a
                      v-on:click.prevent="searchFiltered"
                      class="btn btn-primary w-100 submit"
                      >FILTRAR
                    </a>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
    <section id="searchFooter">
      <div class="container-fluid">
        <div class="inner">
          <p>
            Si no has encontrado lo que buscabas con <span>{{ busqueda }}</span
            >, vuelve a buscar.
          </p>
          <search-bar />
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { getFilteredPets, getAllPets } from "@/db";
import PetList from "@/components/Home/PetList.vue";
import SearchBar from "@/components/Common/SearchBar.vue";
import Loader from "@/components/Common/Loader.vue";

export default {
  name: "SearchResults",
  components: {
    PetList,
    SearchBar,
    Loader,
  },
  data() {
    return {
      busqueda: "",
      busquedaFiltrada: false,
      loading: true,
      pets: [],
      filter: {
        location: "",
        species: "",
        sex: "",
        size: "",
        handle_status: {
          vaccinated: false,
          dewormed: false,
          sterilized: false,
          microchip: false,
        },
        status: "",
      },
    };
  },
  async created() {
    await this.search();
  },
  mounted() {
    window.scrollTo(0, 0);
  },
  methods: {
    isFiltered: function () {
      if (
        this.filter.location !== "" ||
        this.filter.species !== "" ||
        this.filter.sex !== "" ||
        this.filter.size !== "" ||
        this.filter.handle_status.vaccinated !== false ||
        this.filter.handle_status.dewormed !== false ||
        this.filter.handle_status.sterilized !== false ||
        this.filter.handle_status.microchip !== false ||
        this.filter.status !== ""
      ) {
        return true;
      } else {
        return false;
      }
    },
    setValue: function (key, value) {
      if (this.filter[key] === value) {
        this.filter[key] = "";
      } else {
        this.filter[key] = value;
      }
    },
    search: async function () {
      this.loading = true;
      this.busqueda = this.$router.currentRoute.value.params.text;
      this.pets = await getFilteredPets(this.busqueda);
      this.loading = false;
    },
    searchFiltered: async function () {
      this.loading = true;
      this.busquedaFiltrada = true;
      let pets = [];
      if (this.busqueda !== "") {
        pets = await getFilteredPets(this.busqueda);
      } else {
        pets = await getAllPets(this.busqueda);
      }

      if (this.filter.location !== "") {
        pets = pets.filter((pet) => pet.location === this.filter.location);
      }

      if (this.filter.species !== "") {
        pets = pets.filter((pet) => pet.species === this.filter.species);
      }

      if (this.filter.sex !== "") {
        pets = pets.filter((pet) => pet.sex === this.filter.sex);
      }

      if (this.filter.size !== "") {
        pets = pets.filter((pet) => pet.size === this.filter.size);
      }

      if (this.filter.handle_status.vaccinated !== false) {
        pets = pets.filter(
          (pet) =>
            pet.handle_status.vaccinated ===
            this.filter.handle_status.vaccinated
        );
      }

      if (this.filter.handle_status.dewormed !== false) {
        pets = pets.filter(
          (pet) =>
            pet.handle_status.dewormed === this.filter.handle_status.dewormed
        );
      }

      if (this.filter.handle_status.sterilized !== false) {
        pets = pets.filter(
          (pet) =>
            pet.handle_status.sterilized ===
            this.filter.handle_status.sterilized
        );
      }

      if (this.filter.handle_status.microchip !== false) {
        pets = pets.filter(
          (pet) =>
            pet.handle_status.microchip === this.filter.handle_status.microchip
        );
      }

      if (this.filter.status !== "") {
        pets = pets.filter((pet) => pet.status === this.filter.status);
      }

      this.pets = pets;
      this.loading = false;
    },
  },
  watch: {
    // eslint-disable-next-line no-unused-vars
    async $route(to, from) {
      await this.search();
    },
  },
};
</script>

<!-- Añadimos "scoped" para limitar el CSS a este componente -->
<style scoped lang="sass">
.searchResults
  min-height: calc(100vh - 88px)
  display: flex
  flex-direction: column
  justify-content: space-between
  #main
    h1
      font-weight: 800
      font-size: 2.2rem
      font-family: $font-family-title
      margin: 8rem 0 0rem 0
      > span
        color: $primary
      &.h1continuation
        margin: .3rem 0 0rem 0

    .btnLabels
      font-weight: 500
      color: $primary
      text-transform: uppercase
        //text-align: center
      margin: 1.75rem 0 .5rem 0
      font-size: 1.1rem
      &.first
        margin: 1.75rem 0 .5rem 0
    .btn
      &.submit
        margin-top: 2.5rem
    .btn-outline-primary:hover
      box-shadow: 0 0 0 0.25rem #5b49fe80
      background-color: #7d6eff

    .badge
      font-size: 1rem
      color: white
      font-weight: 400
      margin: 1rem .25rem .5rem .25rem

  #searchFooter
    padding: 0
    .container-fluid
      padding: 2rem 1rem
      background: linear-gradient(73.24deg, $accent-light 0%, $accent 100%)
      .inner
        padding: 0
        display: flex
        flex-direction: column
        max-width: 476px
        margin: 0 auto
      p
        text-align: center
        font-size: 1.1rem
        span
          font-weight: 800

/* XL devices and up */
@media (min-width: $xl)
  .searchResults
    #main
      h1
        margin: 9rem 0 2.5rem 0
        &.h1continuation
          margin: 9rem 0 2.5rem 0
      .badge
        margin: 9rem .5rem 2.5rem .5rem
      .btnLabels
        margin: 2.5rem 0 .5rem 0
        &.first
          margin-top: 0
      .btn
        &.submit
          margin-top: 4.5rem
    #petFooter
      padding: 2rem 0 0 0
      .container-fluid
        padding: 2.5rem
</style>
