<template>
  <div class="petContainer col-6 col-md-4 col-lg-3 g-3 mt-0">
    <div class="card" @click="this.$router.push(`/pet/${this.pet.id}`)">
      <div :id="`animal_${this.pet.id}`" class="card-img-top card-color">
        <img :src="pet.image_url" :alt="pet.name" width="" height="" />
      </div>
      <div class="card-body">
        <div class="row">
          <div class="col">
            <h3>{{ pet.name }}</h3>
            <p class="card-text">{{ pet.location }}</p>
          </div>
          <div class="col-3 card-icons">
            <span :class="pet.sex.toLowerCase()"></span>
            <span :class="pet.species.toLowerCase()"></span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PetCard",
  props: {
    pet: Object,
  },
};
</script>

<style scoped lang="sass">
.card
  margin-bottom: 2rem
  -webkit-box-shadow: 0px 1px 5px 0px rgba(130, 130, 130, 0.73)
  box-shadow: 0px 1px 5px 0px rgba(130, 130, 130, 0.73)
  transition: all 0.3s ease-in-out
  cursor: pointer
  border: 0
  &:hover
    -webkit-box-shadow: 0px 2px 7px 0px rgba(85, 85, 85, 0.95)
    box-shadow: 0px 2px 7px 0px rgba(85, 85, 85, 0.95)
    img
      transform: scale(1.1)

  .card-img-top
    height: 115px
    overflow: hidden
    img
      height: 100%
      max-width: 100%
      margin: 0 auto
      display: block
      transition: all 0.3s ease-in-out

  .card-body
    > .row
      font-weight: 600
      font-size: 1.2rem
      margin-bottom: 0
      display: flex
      justify-content: space-between
      align-items: center
      h3
        font-weight: 600
        font-size: 1.2rem
        margin-bottom: 0
      .card-text
        font-weight: 400
        font-size: 0.85rem
        color: #263238
        opacity: 0.8
      .card-icons
        padding-right: calc(var(--bs-gutter-x) * 0.4)
        padding-left: 0
        display: flex
        flex-direction: column
        align-items: center
        > span:first-child
          margin-bottom: 0.35rem
        .macho
          background-image: url("../../assets/icons/icon_male.svg")
          background-repeat: no-repeat
          width: 16px
          height: 16px
        .hembra
          background-image: url("../../assets/icons/icon_female.svg")
          background-repeat: no-repeat
          width: 11px
          height: 17px
        .gato
          background-image: url("../../assets/icons/icon_cat.svg")
          background-repeat: no-repeat
          width: 18px
          height: 18px
        .perro
          background-image: url("../../assets/icons/icon_dog.svg")
          background-repeat: no-repeat
          width: 22px
          height: 18px

/* Medium devices (tablets, 768px and up)*/
@media (min-width: $sm)
  .card
    .card-img-top
      height: 130px

/* Large devices (desktops, 992px and up)*/
@media (min-width: $md)
  .card
    margin-bottom: 2.75rem
    .card-img-top
      height: 145px

    .card-body
      > .row
        .card-icons
          flex-direction: row
          justify-content: center
          > span:first-child
            margin-bottom: 0
            margin-right: 0.55rem

/* X-Large devices (large desktops, 1200px and up)*/
@media (min-width: $lg)
  .card
    .card-img-top
      height: 190px
</style>
