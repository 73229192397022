<template>
  <section id="hero">
    <div class="container">
      <div class="row">
        <div class="col-12 col-md-7 col-lg-6 col-xl-5">
          <h1>Perretes y gatetes <span>en tu zona</span></h1>
          <p>
            Te ayudamos a encontrar el amigo fiel que necesitas. Lo tienes a una
            pata de distancia.
          </p>
          <search-bar />
        </div>
        <div id="imgs" class="col-12 col-md-5 col-lg-6 col-xl-7">
          <img
            class="garabato"
            src="@/assets/images/hero_image_bg.svg"
            alt="Imagen Garabato"
          />
          <img
            class="heroDog"
            src="@/assets/images/pets/animal_hero.png"
            alt="Perro Hero"
          />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import SearchBar from "@/components/Common/SearchBar.vue";

export default {
  name: "Hero",
  components: {
    SearchBar,
  },
};
</script>

<style scoped lang="sass">
#hero
  background: linear-gradient(73.24deg, $accent-light 0%, $accent 100%)
  overflow: hidden
  h1
    padding-top: 7rem
    font-weight: 800
    font-size: 3rem
    font-family: $font-family-title
    margin-bottom: 1.75rem
    > span
      color: #fff

  p
    font-size: 1.1rem
    margin-bottom: 1.5rem

  #imgs
    margin-top: 2rem
    position: relative
    min-height: 250px
    .garabato
      height: 100%
      position: absolute
      bottom: 0
      right: 40px
    .heroDog
      height: 100%
      position: absolute
      bottom: 0
      right: 105px

/* Medium devices (tablets, 768px and up)*/
@media (min-width: $sm)
  #hero
    #buscarPet
      margin-bottom: 3rem
    #imgs
      .garabato
        right: -185px
        bottom: -40px
      .heroDog
        right: -20px

/* Large devices (desktops, 992px and up)*/
@media (min-width: $md)
  #hero
    h1
      padding-top: 10rem
      font-size: 3.5rem

    #buscarPet
      input
        padding: 1rem 2rem

    #imgs
      min-height: 500px
      .garabato
        right: -125px
        bottom: 0
      .heroDog
        right: 30px
        bottom: 0

/* X-Large devices (large desktops, 1200px and up)*/
@media (min-width: $lg)
  #hero
    #imgs
      .garabato
        right: 0
      .heroDog
        right: 125px
</style>
