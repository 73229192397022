<template>
  <div class="newPet">
    <section id="main">
      <div class="container">
        <h1>Añade una nueva <span>mascota</span></h1>
        <form class="mb-5">
          <div class="row">
            <div class="col-12 col-md-6">
              <!--NOMBRE-->
              <div class="form-group">
                <label class="btnLabels first" for="name">Nombre</label>
                <span class="obligatorio">*</span>
                <input
                  :disabled="loading"
                  type="text"
                  id="name"
                  class="form-control"
                  v-model="pet.name"
                />
              </div>

              <!--LOCALIZACION Y NACIMIENTO-->
              <div class="row">
                <div class="col-12 col-md-6">
                  <div class="form-group">
                    <label class="btnLabels" for="location">Localización</label>
                    <span class="obligatorio">*</span>
                    <input
                      :disabled="loading"
                      type="text"
                      id="location"
                      class="form-control"
                      v-model="pet.location"
                    />
                  </div>
                </div>
                <div class="col-12 col-md-6">
                  <div class="form-group">
                    <label class="btnLabels" for="birth_date">Nacimiento</label>
                    <span class="obligatorio">*</span>
                    <input
                      :disabled="loading"
                      type="date"
                      id="birth_date"
                      class="form-control"
                      v-model="pet.birth_date"
                    />
                  </div>
                </div>
              </div>

              <!--ESPECIEs-->
              <div class="form-group">
                <label class="btnLabels" for="species">Especies</label>
                <span class="obligatorio">*</span>
                <div class="d-flex justify-content-around">
                  <input
                    :disabled="loading"
                    type="radio"
                    class="btn-check"
                    v-model="pet.species"
                    value="Gato"
                    id="gato"
                    name="gato"
                  />
                  <label class="btn btn-outline-primary w-100 mx-1" for="gato"
                    >Gato</label
                  >
                  <input
                    :disabled="loading"
                    type="radio"
                    class="btn-check"
                    v-model="pet.species"
                    value="Perro"
                    id="perro"
                    name="perro"
                  />
                  <label class="btn btn-outline-primary w-100 mx-1" for="perro"
                    >Perro</label
                  >
                </div>
              </div>

              <!--SEXO-->
              <div class="form-group">
                <label class="btnLabels" for="sex">Sexo</label>
                <span class="obligatorio">*</span>
                <div class="d-flex justify-content-around">
                  <input
                    :disabled="loading"
                    class="btn-check"
                    type="radio"
                    v-model="pet.sex"
                    value="Macho"
                    id="macho"
                    name="macho"
                  />
                  <label class="btn btn-outline-primary w-100 mx-1" for="macho"
                    >Macho</label
                  >
                  <input
                    :disabled="loading"
                    class="btn-check"
                    type="radio"
                    v-model="pet.sex"
                    value="Hembra"
                    id="hembra"
                    name="hembra"
                  />
                  <label class="btn btn-outline-primary w-100 mx-1" for="hembra"
                    >Hembra</label
                  >
                </div>
              </div>

              <!--TAMAÑO-->
              <div class="form-group">
                <label class="btnLabels" for="size">Tamaño</label>
                <span class="obligatorio">*</span>
                <div class="d-flex justify-content-around">
                  <input
                    :disabled="loading"
                    class="btn-check"
                    type="radio"
                    v-model="pet.size"
                    value="Pequeño"
                    id="pequeno"
                    name="pequeno"
                  />
                  <label
                    class="btn btn-outline-primary w-100 mx-1"
                    for="pequeno"
                    >Pequeño</label
                  >
                  <input
                    :disabled="loading"
                    class="btn-check"
                    type="radio"
                    v-model="pet.size"
                    value="Mediano"
                    id="mediano"
                    name="mediano"
                  />
                  <label
                    class="btn btn-outline-primary w-100 mx-1"
                    for="mediano"
                    >Mediano</label
                  >
                  <input
                    :disabled="loading"
                    class="btn-check"
                    type="radio"
                    v-model="pet.size"
                    value="Grande"
                    id="grande"
                    name="grande"
                  />
                  <label class="btn btn-outline-primary w-100 mx-1" for="grande"
                    >Grande</label
                  >
                </div>
              </div>

              <!--ESTADO-->
              <div class="form-group">
                <label class="btnLabels" for="status">Estado</label>
                <span class="obligatorio">*</span>
                <div
                  class="d-flex justify-content-around flex-column flex-md-row"
                >
                  <input
                    :disabled="loading"
                    class="btn-check"
                    type="radio"
                    v-model="pet.status"
                    value="En adopción"
                    id="enAdopcion"
                    name="enAdopcion"
                  />
                  <label
                    class="btn btn-outline-primary w-100 mx-1 mb-2 mb-xl-0"
                    for="enAdopcion"
                    >En adopción</label
                  >
                  <input
                    :disabled="loading"
                    class="btn-check"
                    type="radio"
                    v-model="pet.status"
                    value="Adoptado"
                    id="adoptado"
                    name="adoptado"
                  />
                  <label
                    class="btn btn-outline-primary w-100 mx-1 mb-2 mb-xl-0"
                    for="adoptado"
                    >Adoptado</label
                  >
                  <input
                    :disabled="loading"
                    class="btn-check"
                    type="radio"
                    v-model="pet.status"
                    value="En acogida"
                    id="enAcogida"
                    name="enAcogida"
                  />
                  <label
                    class="btn btn-outline-primary w-100 mx-1 mb-2 mb-xl-0"
                    for="enAcogida"
                    >En acogida</label
                  >
                  <input
                    :disabled="loading"
                    class="btn-check"
                    type="radio"
                    v-model="pet.status"
                    value="Acogido"
                    id="acogido"
                    name="acogido"
                  />
                  <label
                    class="btn btn-outline-primary w-100 mx-1"
                    for="acogido"
                    >Acogido</label
                  >
                </div>
              </div>

              <!--ESTADO DE LA ENTREGA-->
              <div class="form-group">
                <label class="btnLabels" for="handle_status"
                  >Estado en la entrega</label
                >
                <span class="obligatorio">*</span>
                <div
                  class="d-flex justify-content-around flex-column flex-md-row"
                >
                  <input
                    :disabled="loading"
                    class="btn-check"
                    type="checkbox"
                    id="vaccinated"
                    v-model="pet.handle_status.vaccinated"
                  />
                  <label
                    class="btn btn-outline-primary w-100 mx-1 mb-2 mb-xl-0"
                    for="vaccinated"
                  >
                    Vacunado
                  </label>
                  <input
                    :disabled="loading"
                    class="btn-check"
                    type="checkbox"
                    id="dewormed"
                    v-model="pet.handle_status.dewormed"
                  />
                  <label
                    class="btn btn-outline-primary w-100 mx-1 mb-2 mb-xl-0"
                    for="dewormed"
                  >
                    Desparasitado
                  </label>
                  <input
                    :disabled="loading"
                    class="btn-check"
                    type="checkbox"
                    id="sterilized"
                    v-model="pet.handle_status.sterilized"
                  />
                  <label
                    class="btn btn-outline-primary w-100 mx-1 mb-2 mb-xl-0"
                    for="sterilized"
                  >
                    Esterilizado
                  </label>
                  <input
                    :disabled="loading"
                    class="btn-check"
                    type="checkbox"
                    id="microchip"
                    v-model="pet.handle_status.microchip"
                  />
                  <label
                    class="btn btn-outline-primary w-100 mx-1"
                    for="microchip"
                  >
                    Microchip
                  </label>
                </div>
              </div>
            </div>
            <div class="col-12 col-md-6">
              <div class="row">
                <div class="col-12 col-md-6">
                  <!--HISTORIA-->
                  <div class="form-group">
                    <label class="btnLabels first" for="bio">Historia</label>
                    <span class="obligatorio">*</span>
                    <textarea
                      :disabled="loading"
                      style="height: 393px"
                      placeholder="Cuéntanos la historia de la mascota..."
                      type="text"
                      id="bio"
                      class="form-control"
                      v-model="pet.bio"
                    />
                  </div>

                  <!--INFO NOTES-->
                  <div class="form-group">
                    <label class="btnLabels" for="info_notes"
                      >Información adicional</label
                    >
                    <input
                      :disabled="loading"
                      type="text"
                      id="info_notes"
                      class="form-control"
                      v-model="pet.input_notes"
                      @keypress.enter.prevent.stop.self="
                        addInfoNotes($event.target.value)
                      "
                    />
                    <span class="text-muted mt-1 mb-2 d-block"
                      >Escribe y pulsa la tecla ENTER para añadir la
                      información</span
                    >
                  </div>

                  <div class="form-group notesContainer">
                    <span
                      v-for="(note, index) in pet.info_notes"
                      :key="index"
                      class="badge bg-primary me-2 mb-1"
                    >
                      {{ note }}
                      <span class="remove" v-on:click="removeNote(note)"
                        >X</span
                      >
                    </span>
                  </div>

                  <!--IMG-->
                  <div class="form-group">
                    <label class="btnLabels first" for="image_file"
                      >Selecciona una Imagen</label
                    >
                    <span class="obligatorio">*</span>
                    <input
                      :disabled="loading"
                      placeholder="https://"
                      type="file"
                      id="image_file"
                      class="form-control"
                      v-on:change="addImageFile($event.target.files[0])"
                      @change="previewImage"
                      accept="image/*"
                    />
                  </div>

                  <!--IMG-->
                  <div class="form-group">
                    <label class="btnLabels first mt-3" for="image_url"
                      >o indica una url</label
                    >
                    <input
                      :disabled="loading"
                      placeholder="https://"
                      type="url"
                      id="image_url"
                      class="form-control"
                      v-model="pet.image_url"
                    />
                  </div>
                </div>
                <div class="col-12 col-md-6">
                  <!--PERSONALIDAD-->
                  <div class="form-group">
                    <label class="btnLabels first" for="personality"
                      >Personalidad</label
                    >
                    <select
                      :disabled="loading"
                      style="height: 508px"
                      class="form-select overflow-hidden"
                      multiple
                      name="personality"
                      id="personality"
                      v-model="pet.personality"
                      @mousedown.prevent.stop="
                        addPersonality($event.target.value)
                      "
                    >
                      <option value="Bueno con niños">Bueno con niños</option>
                      <option value="Bueno con perros">Bueno con perros</option>
                      <option value="Bueno con gatos">Bueno con gatos</option>
                      <option value="Bueno en casa">Bueno en casa</option>
                      <option value="Bueno en el coche">
                        Bueno en el coche
                      </option>
                      <option value="Tímido">Tímido</option>
                      <option value="Juguetón">Juguetón</option>
                      <option value="Cariñoso">Cariñoso</option>
                      <option value="Me gusta estar en compañía">
                        Me gusta estar en compañía
                      </option>
                      <option value="Me gusta pasear">Me gusta pasear</option>
                      <option value="Independiente">Independiente</option>
                      <option value="Cauteloso con extraños">
                        Cauteloso con extraños
                      </option>
                      <option value="Amigable con extraños">
                        Amigable con extraños
                      </option>
                      <option value="A veces ladro">A veces ladro</option>
                    </select>
                  </div>

                  <!--IMG-->
                  <div class="row">
                    <div
                      v-if="this.pet.image_file.url || this.pet.image_url"
                      class="col-12 imgContainer"
                    >
                      <img
                        :src="this.pet.image_file.url || this.pet.image_url"
                        :alt="this.pet.name"
                        class="mh-100 mw-100"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="row mt-5">
            <div class="col-12 col-md-6">
              <div v-if="error !== null" class="form-error">
                <div class="alert alert-danger" role="alert">
                  {{ error }}
                </div>
              </div>
              <div v-if="success !== null" class="form-error">
                <div class="alert alert-success" role="alert">
                  <span>{{ pet.name }}</span> añadido a Petinder
                </div>
              </div>
            </div>
            <div class="col-12 col-md-6">
              <a
                v-on:click.prevent="createPet"
                class="btn btn-primary w-100 submit"
                >+ AÑADIR
              </a>
            </div>
          </div>
        </form>
      </div>
    </section>
  </div>
</template>

<script>
import { addPet } from "@/db";

import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";

export default {
  name: "NewPet",
  components: {},
  data() {
    return {
      pet: {
        id: "",
        name: "",
        image_url: "",
        image_file: {
          img: {},
          url: "",
        },
        location: "",
        species: "",
        sex: "",
        size: "",
        birth_date: "",
        handle_status: {
          vaccinated: false,
          dewormed: false,
          sterilized: false,
          microchip: false,
        },
        bio: "",
        status: "",
        personality: [],
        input_notes: "",
        info_notes: [],
      },
      loading: false,
      error: null,
      success: null,
    };
  },
  async mounted() {
    window.scrollTo(0, 0);
  },
  methods: {
    addPersonality: function (pers) {
      if (this.pet.personality.includes(pers)) {
        this.pet.personality.splice(this.pet.personality.indexOf(pers), 1);
      } else {
        this.pet.personality.push(pers);
      }
    },
    addInfoNotes: function (note) {
      if (note !== "") {
        this.pet.info_notes.push(note);
        this.pet.input_notes = "";
      }
    },
    removeNote: function (note) {
      this.pet.info_notes.splice(this.pet.info_notes.indexOf(note), 1);
    },
    addImageFile: function (image_file) {
      this.pet.image_file.img = image_file;
      this.pet.image_file.url = URL.createObjectURL(image_file);
    },
    createPet: async function () {
      this.loading = true;

      const validationError = await this.validateForm();
      if (validationError) {
        this.error = validationError;
        this.success = null;
        this.loading = false;
        return;
      }

      if (this.pet.image_file.url !== "") {
        const uploadError = await this.uploadPhoto();
        if (uploadError) {
          this.error = "Ha ocurrido un error temporal subiendo la imagen";
          this.success = null;
          this.loading = false;
          return;
        }
      }

      this.error = null;

      //console.log("this.pet.image_file.url", this.pet.image_file.url);
      //console.log("this.pet.image_url", this.pet.image_url);

      const addSuccess = await addPet({
        id: this.pet.id,
        name: this.pet.name,
        image_url: this.pet.image_file.url || this.pet.image_url,
        location: this.pet.location,
        species: this.pet.species,
        sex: this.pet.sex,
        size: this.pet.size,
        birth_date: this.pet.birth_date,
        handle_status: {
          vaccinated: this.pet.handle_status.vaccinated,
          dewormed: this.pet.handle_status.dewormed,
          sterilized: this.pet.handle_status.sterilized,
          microchip: this.pet.handle_status.microchip,
        },
        bio: this.pet.bio,
        status: this.pet.status,
        personality: this.pet.personality,
        info_notes: this.pet.info_notes,
      });

      if (addSuccess) {
        this.success = addSuccess;
        await new Promise((r) => setTimeout(r, 2000)).then(() => {
          this.pet = {
            id: "",
            name: "",
            image_url: "",
            image_file: {
              img: {},
              url: "",
            },
            location: "",
            species: "",
            sex: "",
            size: "",
            birth_date: "",
            handle_status: {
              vaccinated: false,
              dewormed: false,
              sterilized: false,
              microchip: false,
            },
            bio: "",
            status: "",
            personality: [],
            input_notes: "",
            info_notes: [],
          };
          this.success = null;
          this.loading = false;
        });
      } else {
        this.error = "Error temporal de conexión con la base de datos.";
        this.success = null;
        this.loading = false;
      }
    },
    validateForm: async function () {
      if (this.pet.name === "") {
        return "Indica un nombre válido";
      } else if (this.pet.location == "") {
        return "Indica la localización del animal";
      } else if (this.pet.birth_date == "") {
        return "Indica la fecha de nacimineto del animal";
      } else if (this.pet.species == "") {
        return "Indica la especies del animal";
      } else if (this.pet.sex == "") {
        return "Indica el sexo del animal";
      } else if (this.pet.size == "") {
        return "Indica el tamaño del animal";
      } else if (this.pet.bio == "") {
        return "Indica la biografía del animal";
      } else if (this.pet.status == "") {
        return "Indica el estado actual del animal";
      } else if (this.pet.image_url == "" && this.pet.image_file.url == "") {
        return "Añade una imágen del animal";
      } else {
        return null;
      }
    },
    uploadPhoto: async function () {
      try {
        const storage = getStorage();
        const storageRef = ref(storage, this.pet.image_file.img.name);
        await uploadBytes(storageRef, this.pet.image_file.img).then(
          async () => {
            await getDownloadURL(storageRef).then((url) => {
              //console.log("url", url);
              this.pet.image_file.url = url;
              return false;
            });
          }
        );
      } catch (error) {
        return error;
      }
    },
  },
};
</script>

<!-- Añadimos "scoped" para limitar el CSS a este componente -->
<style scoped lang="sass">
#main
  h1
    font-weight: 800
    font-size: 2.2rem
    font-family: $font-family-title
    margin: 8rem 0 1rem 0
    > span
      color: $primary
  .input-group-text
    width: 40%
  .form-control
    padding: 0.53rem 0.75rem
    border-color: $primary
  .form-select
    border: 1px solid $primary
    &:focus
      option:checked
        background-color: $primary !important
        color: white !important
    option:checked
      background-color: $primary
      color: white
  .btnLabels
    font-weight: 500
    color: $primary
    text-transform: uppercase
      //text-align: center
    margin: 1.75rem 0 .5rem 0
    font-size: 1.1rem
    &.first
      margin: 1.75rem 0 .5rem 0
  .btn
    padding: .5rem 1rem
    &.submit
      padding: 1rem 1rem
  .alert
    text-align: center
    text-transform: uppercase
    span
      font-weight: 600
  option
    padding: 0.5rem 0rem
    text-align: center
  .obligatorio
    color: $red
    font-size: .9rem
    margin-left: .3rem
  .imgContainer
    height: 327px
    display: flex
    align-items: flex-end
  .badge
    font-size: .9rem
  .notesContainer
    height: 126px
    overflow: auto
    .remove
      cursor: pointer
      margin-left: .3rem
      font-size: .7rem
      color: white
  .text-muted
    font-size: .7rem
/* Large devices (desktops, 992px and up)*/
@media (min-width: $md)
  #main
    h1
      margin: 9rem 0 3rem 0
    .btnLabels
      margin: 2.5rem 0 .5rem 0
      &.first
        margin-top: 0
</style>
